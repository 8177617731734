.form__container{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 55px 55px;
    box-sizing: border-box;
    /* background-color: darkblue; */
    background-image: url("../../public/dist/img/background.png");
    background-size: cover;
    background-position: center;
    height: 100vh;
    background-repeat:no-repeat;
}

.loginbox{
    width: 320px;
    background: #000;
    color: #fff;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 50px 30px;
}

.avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    left: calc(50% - 50px);
}

.title{
    margin: 0;
    padding: 0 0 10px;
    text-align: center;
    font-size: 22px;
}

.loginbox label{
    margin: 0;
    padding: 0;
    font-weight: bold;
}

.loginbox input{
    width: 100%;
    margin-bottom: 10px;
}

.loginbox input[type = "text"], input[type = "email"], input[type = "password"]{
    /* border: none; */
    border-bottom: 1px solid #fff;
    /* background: transparent; */
    /* outline: none; */
    /* height: 40px; */
    /* color: #fff; */
    font-size: 16px;
}

.loginbox input[type = "submit"]{
    border: none;
    outline: none;
    height: 40px;
    background: #FFFF8F;
    color: #06421d;
    font-size: 18px;
    border-radius: 20px;
}

.loginbox input[type = "submit"]:hover{
    cursor: pointer;
    background: #ffc107;
    color: #000;
}