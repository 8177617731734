.explore{
    padding:117px 0 95px;
    background: #f8fafb;
}
.explore-content{margin-top: 78px;}

.single-explore-item {
    background: #fff;
    border: 1px solid #edeff1;
    border-radius: 3px;
    margin-bottom: 25px;
    -webkit-transition: .3s linear; 
    -moz-transition:.3s linear; 
    -ms-transition:.3s linear; 
    -o-transition:.3s linear;
    transition: .3s linear;
}
.single-explore-img{position:relative;overflow: hidden;}
.single-explore-img:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(162,172,177,.2);
}
.single-explore-img-info {
    position: absolute;
    bottom:-20px;
    left: 0;
    width: 100%;
    opacity:0;
    visibility:hidden;
    -webkit-transition: .3s linear;
    -moz-transition: .3s linear;
    -ms-transition: .3s linear;
    -o-transition: .3s linear;
    transition: .3s linear;
}
.single-explore-item:hover .single-explore-img-info{
    opacity:1;
    visibility:visible;
    bottom:0px
}
.single-explore-img-info button{
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 83px;
    /* height: 21px; */
    line-height: 21px;
    background: #ff545a;
    border-radius: 3px;
    color: #fcfcfc;
    text-transform: capitalize;
    text-align: center;
    font-size: 12px;
}
.single-explore-image-icon-box {
    text-align: right;
    position: absolute;
    bottom: 0px;
    right:  10px;
}
.single-explore-image-icon-box ul li {
    display:  inline-block;
    width: 30px;
    height:  28px;
    line-height:  28px;
    background: #252d32;
    text-align:  center;
    margin-left:  5px;
    color:  #cbcccd;
}
.single-explore-image-icon-box ul li:hover i{color: #267dff;}

.single-explore-txt {
    padding: 26px 25px 24px 15px;
}
.single-explore-txt.bg-theme-1 .explore-rating{background: #70a9ff;}
.single-explore-txt.bg-theme-2 .explore-rating{background: #00c61c;}
.single-explore-txt.bg-theme-3 .explore-rating{background: #ffcc5d;}
.single-explore-txt.bg-theme-4 .explore-rating{background: #bd70ff;}
.single-explore-txt.bg-theme-5 .explore-rating{background: #ff7a40;}

.explore-rating-price,.explore-rating-price a {
    font-size: 12px;
    color: #777f85;
    text-transform: capitalize;
    font-weight: 400;
    margin: 15px 0 20px;
}
.explore-rating-price a {margin:0;}
.explore-rating {
    display: inline-block;
    width: 32px;
    height: 20px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    font-weight: 500;
    margin-right: 10px;
}
.explore-price {color: #f63138;}
.explore-price-box {
    display: inline-block;
    padding: 0 10px;
    margin: 0 8px;
    border-left: 1px solid #dde0e4;
    border-right: 1px solid #dde0e4;
}
.explore-person {
    padding-bottom: 28px;
    border-bottom: 1px solid #e1e5eb;
}
.explore-person-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.explore-person p {font-size: 12px;}
.explore-open-close-part {
    margin-top: 20px;
}
.close-btn {
    color: #f63138;
    text-transform: capitalize;
}
.close-btn.open-btn {color: #00c437;}
.explore-map-icon{text-align: right;}
.explore-map-icon a svg {
    width: 12px;
    height: 14px;
    margin-left: 23px;
    color: #767f86;
}
.explore-map-icon a svg:hover{color: #f63138;}
.single-explore-txt.bg-theme-2 .explore-map-icon a svg:hover{color: #00c437;}
.single-explore-item:hover{box-shadow: 0 10px 20px rgba(21,19,19,.2);}
