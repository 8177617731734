*{
	padding:0;
	margin:0;
    -webkit-transition: 1s;
    transition: 1s;
}
.clrd-font{
	background: #FF512F;
	background: -webkit-linear-gradient(to right, #F09819, #FF512F);
	background: linear-gradient(to right, #F09819, #FF512F);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}


.single_portfolio_text{
	display:inline-block;
	padding:0;
	position:relative;
	overflow:hidden;
}
.single_portfolio_text img{
	width:100%;
}

.single_portfolio_text:hover .portfolio_images_overlay{
	top:5%;
	left: 5%;
}

.portfolio_images_overlay{
	width: 90%;
	height: 90%;
	background: rgba(0, 0, 0, 0.8);
	padding: 20px;
	margin: 0 auto;
	top: -100%;
	left: 5%;
	position: absolute;
	transition:.6s;
	
}
.portfolio_images_overlay h6{
	text-transform: uppercase;
	color: #fff;
	font-size: 30px;
	font-weight: 900;
	border: 2px solid #fff;
	padding-top: 10px;
	padding-bottom: 10px;
}

.portfolio_images_overlay .product_price{
	font-size: 35px;
	color: #fff;
	font-weight:800;
	line-height:30px;
}
.portfolio_images_overlay .product_price i{
    margin-right: -10px;
}
.zoom{
	width: 200px;
    height: 200px;
    position: absolute;
    bottom: -100px;
    right: -100px;
    border-radius: 50%;

	background: #FF512F;
	background: -webkit-linear-gradient(to right, #F09819, #FF512F);
	background: linear-gradient(to right, #F09819, #FF512F);
    box-shadow:0px 0px 0px 10px rgba(0,0,0,0.5);
	opacity:0.9;
}
.zoom:before {
	content: "\f00e";
    font-family: FontAwesome;
    color: rgba(255, 255, 255, 0.5);
    font-size: 70px;
    padding-right: 20px;
    position: absolute;
    top: 10px;
    left: 30px;
}
@media (min-width:769px) and (max-width:991px) {
	.portfolio_images_overlay {
		padding: 0px;
	}
}
@media (max-width:768px) {
	.portfolio_images_overlay{
		padding: 170px 20px;
	}
}
@media (max-width:580px) {
	.portfolio_images_overlay{
		padding: 100px 20px;
	}
}
@media (max-width:480px) {
	.portfolio_images_overlay{
		padding: 40px 20px;
	}
}
@media (max-width:320px) {
	.portfolio_images_overlay{
		padding: 20px;
	}
}